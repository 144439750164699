import React from "react";

export default function BlogPost({
  title,
  date,
  thumbnail,
  body,
  tableOfContents
}) {
  return (
    <div className="blog-post-container">
      <article className="post">
        {!thumbnail && (
          <div className="post-thumbnail">
            <h1 className="post-title">{title}</h1>
            <div className="post-meta">{date}</div>
          </div>
        )}
        {!!thumbnail && (
          <div
            className="post-thumbnail"
            style={{ backgroundImage: `url(${thumbnail})` }}
          >
            <h1 className="post-title">{title}</h1>
            <div className="post-meta">{date}</div>
          </div>
        )}
        <div className="blog-post-content">
          {!!tableOfContents && <div className="table-of-contents">{tableOfContents}</div>}
          {body}
        </div>
      </article>
    </div>
  );
}
