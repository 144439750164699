import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { DiscussionEmbed } from "disqus-react";
import Layout from "../components/layout";
import BlogPost from "../components/blogPost";

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html, tableOfContents } = markdownRemark;

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: frontmatter.path, title: frontmatter.title }
  };

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>
      <BlogPost
        title={frontmatter.title}
        date={frontmatter.date}
        thumbnail={frontmatter.thumbnail}
        body={<span dangerouslySetInnerHTML={{ __html: html }} />}
        tableOfContents={
          <span dangerouslySetInnerHTML={{ __html: tableOfContents }} />
        }
      />
      <div className="disqus-comments">
        <DiscussionEmbed {...disqusConfig} />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      tableOfContents(absolute: false)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
      }
    }
  }
`;
